<messages>["./OrderType"]</messages>

<template>
  <v-chip
    v-t="`${key}`"
    small
    class="operation"/>
</template>

<script>
  export default {
    name: 'OrderType',

    props: {
      name: {type: String, required: true}
    },

    computed: {
      key () {
        return this.name.split ('.').join ('_')
      }
    }
  }
</script>

<style scoped>
.operation {
  float: right;
  margin: 0;
}
</style>
