<!--
This is a component, which can be used to represent sensitive information
like password or auth info, which will be shown only after button click
-->
<template>
  <v-row
    align="center"
    class="ma-0">
    <v-col
      class="pa-0 value"
      v-text="valueText"/>
    <v-col class="buttonContainer text-no-wrap pa-0 pr-1">
      <v-btn
        v-if="!copyOnly"
        small
        icon
        class="ma-0"
        @click="switchVisibility">
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
      <notification
        v-model="showCopyNotification"
        :text="$t ('general.notification.copied')">
        <template #activator="{ on }">
          <v-btn
            small
            icon
            class="ma-0"
            v-on="on"
            @click="copyToClipboard">
            <v-icon>content_copy</v-icon>
          </v-btn>
        </template>
      </notification>
    </v-col>
  </v-row>
</template>

<script>
  import Notification from '@/app/core/components/Notification'

  export default {
    name: 'SecretText',

    components: {
      Notification
    },

    props: {
      value: {
        type: String,
        required: true
      },
      visible: {type: Boolean, default: false},
      copyOnly: {type: Boolean, default: false}
    },

    data () {
      return {
        showCopyNotification: false,
        isTextVisible: this.visible
      }
    },

    computed: {
      valueText () {
        return this.isTextVisible ? this.value : '********'
      },

      icon () {
        return this.isTextVisible ? 'visibility_off' : 'visibility'
      }
    },

    methods: {
      switchVisibility () {
        this.isTextVisible = !this.isTextVisible
      },

      copyToClipboard () {
        this.$copyText (this.value)
        this.showCopyNotification = true
      }
    }
  }
</script>

<style scoped>
.buttonContainer {
  flex-grow: 0;
}
.value {
  word-wrap: break-word;
}
</style>
