<template>
  <v-card
    tile
    :class="[`elevation-${elevation}`, 'flex-grow-1', cardClass]">
    <v-card-title v-if="title" :class="titleClass">
      <strong v-text="title"/>
    </v-card-title>
    <v-card-text :class="contentClass">
      <slot/>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'SimpleCard',

    props: {
      cardClass: {
        type: [Array, Object, String],
        default: undefined
      },

      title: {
        type: String,
        default: undefined
      },

      titleClass: {
        type: [Array, Object, String],
        default: undefined
      },

      elevation: {
        type: Number,
        default: 1
      },

      contentClass: {
        type: [Array, Object, String],
        default: 'pa-2'
      }
    }
  }
</script>
