<messages>["@/app/i18n/common/GenericObject", "./Host"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <base-layout mw1>
    <v-col cols="12">
      <!-- "host does not exist" alert -->
      <v-alert
        type="error"
        :value="!isLoading && !host">
        {{ errorMsg }}
      </v-alert>

      <!-- host details -->
      <v-card v-if="host">
        <v-card-title primary-title class="py-0">
          <div>
            <div>
              <span :class="{headline: true, deletedItemHeadline: host.currentVersionDeleted}">
                {{ host ? host.domainName : hostHandle }}
              </span>
              <template v-if="host ? host.internationalDomainName !== host.domainName : true">
                (<span
                  class="cgwng-subheading"
                  v-text="`${host ? host.internationalDomainName : hostHandle}`"/>)
              </template>
            </div>
            <div class="cgwng-subheading">
              <registry-type-name :value="host.registryId" cut-tlds :max-tlds="5"/>
            </div>
          </div>
        </v-card-title>
        <v-tabs
          v-model="activeTab"
          right
          slider-color="primary">
          <v-tab
            key="current"
            v-t="'general.history.current'"
            :disabled="host.currentVersionDeleted"/>
          <v-tab-item key="current">
            <v-card-text class="pt-0">
              <host-data :host="host"/>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <back-button/>
              <v-btn
                v-if="isSyncable"
                v-t="'general.button.sync'"
                :loading="isLoadingSync"
                @click="syncHost"/>
              <template v-if="isManageable">
                <v-btn
                  v-t="'general.button.manageStates'"
                  :disabled="isStatusModifyDisabled"
                  @click="statesDialog=true"/>
                <v-btn
                  v-t="'general.button.edit'"
                  :disabled="isModifyDisabled"
                  :to="{name: 'host.edit', params: {
                    registry: host.registryId,
                    handle: hostHandle
                  }}"
                  color="primary"/>
              </template>
            </v-card-actions>
            <states-dialog
              v-if="host.id"
              v-model="statesDialog"
              type="host"
              :object="{id: host.id, name: host.handle}"
              @success="loadHostByProps"/>
          </v-tab-item>

          <!-- historic host data -->
          <v-tab
            key="historic"
            v-t="'general.history.historic'"/>
          <v-tab-item key="historic">
            <v-card-text class="pt-2">
              <template v-if="isLoadingHistoric && !historicObjectData">
                <v-progress-linear
                  indeterminate
                  color="primary"/>
              </template>
              <history-panel
                v-for="(data, idx) in historicObjectData"
                v-else
                :id="'version-' + data.versionId"
                :key="data.versionId"
                :expanded="isHistoryPanelExpanded (idx)"
                :seq-number="idx"
                @expand="assignHistoricHost (data, idx)">
                <template #label>
                  <span v-text="idx + 1"/>
                </template>
                <template #start>
                  <span v-text="formatDate (data.from)"/>
                </template>
                <template #end>
                  <span v-text="data.to ? formatDate (data.to) : $t ('general.history.now')"/>
                </template>
                <template #operation>
                  <order-type :name="data.orderType"/>
                </template>
                <template #content>
                  <host-data
                    v-if="!data.isLoading && data.host"
                    history
                    class="ma-0"
                    :host="data.host"/>
                  <v-progress-linear
                    v-else
                    indeterminate
                    color="primary"/>
                </template>
              </history-panel>
            </v-card-text>
          </v-tab-item>
        </v-tabs>
      </v-card>
      <template v-if="isLoading && !host">
        <v-progress-linear
          indeterminate
          color="primary"/>
      </template>
    </v-col>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapGetters, mapActions, mapMutations} from 'vuex'

  import BackButton from '@/app/core/components/BackButton'
  import BaseLayout from '@/app/core/components/BaseLayout'
  import HistoryPanel from '@/app/core/components/HistoryPanel'
  import OrderType from '@/app/core/components/OrderType'
  import RegistryTypeName from '@/app/core/components/RegistryTypeName'

  import StatesDialog, {isUpdateProhibited}
    from '@/app/core/components/RegistryObject/StatesDialog'

  import RegistryCommands from '@/app/core/mixins/RegistryCommands'

  import RegistryObjectView, {HISTORIC}
    from '@/app/core/mixins/RegistryObjectViewHelper'

  import HostData from './components/HostData'

  export default {
    name: 'HostView',

    components: {
      BackButton,
      BaseLayout,
      HostData,
      OrderType,
      HistoryPanel,
      RegistryTypeName,
      StatesDialog
    },

    mixins: [RegistryCommands, RegistryObjectView],

    props: {
      handle: {
        type: String,
        default: undefined
      },
      registry: {
        type: String,
        default: undefined
      }
    },

    data () {
      return {
        registryType: null,
        isLoading: true,
        isLoadingHistoric: false,
        host: null,
        registryMetadata: {},
        statesDialog: false,
        isLoadingSync: false
      }
    },

    computed: {
      ...mapGetters ({
        mayManageObject: 'auth/mayManageObject',
        permissions: 'auth/permissions'
      }),

      loadParams () {
        return {
          registryType: this.registry,
          handle: this.handle,
          id: this.id,
          vid: this.vid
        }
      },

      hostHandle () {
        return this.handle || this.host.handle
      },

      isManageable () {
        return this.mayManageObject (this.host.clientId)
      },

      isModifyDisabled () {
        return this.isUpdateProhibited (this.host?.states) ||
          !this.supports (this.registry, 'HostModify')
      },

      isStatusModifyDisabled () {
        return !this.supports (this.registry, 'HostStatusModify')
      },

      isThinRegistry () {
        return this.registryMetadata?.thinRegistry
      },

      isInquirable () {
        return this.host && !this.host.currentVersionDeleted &&
          this.registryMetadata && !this.isThinRegistry
      },

      isAdmin () {
        return this.permissions.includes ('ManageAllObjects')
      },

      errorMsg () {
        return this.loadParams.registryType
          ? this.$t ('view.invalidHost', this.$route.params)
          : this.$t (
            'view.invalidHostId',
            {id: this.loadParams.id || this.loadParams.vid})
      },

      isSyncable () {
        return this.isInquirable && this.isAdmin
      }
    },

    watch: {
      'host.registryId' (newValue) {
        if (newValue) {
          this.loadRegistryMetaData ()
        }
      }
    },

    created () {
      this.loadHostByProps ()
    },

    methods: {
      ...mapActions ({
        getClientNameExt: 'cache/getClientNameExt',
        fetchData: 'request/fetchData'
      }),

      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),

      /**
       * Load the registry-specific meta data for the registry with the given
       * ID.
       */
      loadRegistryMetaData () {
        this.fetchData ({
          op: 'registry/loadMetaData',
          params: {
            registryId: this.host.registryId
          },
          cb: data => {
            this.registryMetadata = data.registryMetaData
          }
        })
      },

      /**
       * Synchronize the host with the registry
       */
      syncHost () {
        this.isLoadingSync = true
        this.fetchData ({
          op: 'host/sync',
          params: {
            hosts: [{name: this.host.domainName, registryId: this.host.registryId}]
          },
          cb: data => {
            this.displaySuccessMessage (this.$tc ('sync.success'))

            if (data.names[this.host.domainName].startsWith ('deleted')) {
              this.$router.push ({name: 'host.search'})
            } else {
              this.host = undefined
              this.historicObjectData = undefined
              this.loadHostByProps ()
            }
          },
          cbFinal: () => {
            this.isLoadingSync = false
          }
        })
      },

      isUpdateProhibited,

      /**
       * Load the host specified by the given parameters
       *
       * @param {Object} params     the host load params
       */
      loadHost (params) {
        this.isLoading = true

        const op = params.vid ? 'host/loadVersion' : 'host/load'

        this.fetchData ({
          op,
          params,
          cb: data => {
            this.host = {...data.hostData, ...data.viewData}
            this.registryType = data.registryType
            this.setObjectId (this.host.id)

            if (params.vid || this.host.currentVersionDeleted) {
              this.setObjectVersionId (this.host.versionId)
              this.setActiveTab (HISTORIC)
            }

            this.loadHostHistoryData (this.host.id)
          },
          cbError: () => {
            this.host = null
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      },

      /**
       * Load the historic data of a host.
       *
       * @param {Integer} id     the ID of the host to load the history data
       *                         for
       */
      loadHostHistoryData (id) {
        this.isLoadingHistoric = true
        return this.fetchData ({
          op: 'host/history',
          params: {
            id: id
          },
          cb: data => {
            this.historicObjectData = data.historyData.map ((item) => {
              return {...item, isLoading: false, host: null}
            })

            this.expandHistoryPanelWithLoadedVersion ()
          },
          cbFinal: () => {
            this.isLoadingHistoric = false
          }
        })
      },

      /**
       * Assign the data of the host version specified by the given host
       * data object to the host data object.
       *
       * @param {Object} hostData       the data object containing the version
       *                                ID of the host to load
       * @param {Integer} index         expanded item index
       */
      assignHistoricHost (hostData, index = 0) {
        this.setObjectVersionId (hostData.versionId)

        if (index === 0) {
          hostData.host = this.host
        } else if (!hostData.host) {
          hostData.isLoading = true
          this.fetchData ({
            op: 'host/loadVersion',
            params: {
              vid: hostData.versionId
            },
            cb: data => {
              hostData.host = {...data.hostData, ...data.viewData}
            },
            cbFinal: () => {
              hostData.isLoading = false
              this.navigateToCurrentVersion ()
            }
          })
        }
        this.navigateToCurrentVersion ()
      },

      /**
       * Load the host specified in the URL.
       */
      loadHostByProps () {
        this.loadHost (this.loadParams)
      }
    }
  }
</script>
