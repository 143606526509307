<messages>["@/app/i18n/common/GenericObject", "../Host"]</messages>

<template>
  <v-row justify="space-between">
    <!-- "show order" button -->
    <v-col cols="12" class="py-0">
      <v-row>
        <v-col class="pb-0" align-self="center">
          <slot name="controls">
            <v-spacer/>
          </slot>
        </v-col>
        <v-col class="justify-end shrink pb-0" align-self="center">
          <order-dialog
            :order-id="host.orderId"
            :object-id="`${host.domainName} (${host.registryId})`"
            type="host">
            <template #activator="{ on }">
              <v-btn
                v-t="`general.button.show${history ? '' : 'Current'}Order`"
                small
                class="ma-0 elevation-0"
                v-on="on"/>
            </template>
          </order-dialog>
        </v-col>
      </v-row>
    </v-col>

    <!-- host data sections -->
    <v-col
      v-for="section in sections.filter (i => i.show)"
      :key="section.l"
      class="d-flex child-flex"
      cols="12" md="6">
      <data-card :title="$t (`view.section.${section.l}`)">
        <name-value-block :items="section.i"/>
      </data-card>
    </v-col>
  </v-row>
</template>

<script>
  import {EmptyMark, toCamelCase} from '@/app/utils/string'

  import ClientLink from '@/app/core/components/ClientLink'
  import NameValueBlock from '@/app/core/components/NameValueBlock'
  import RegistryTypeLink from '@/app/core/components/RegistryTypeLink'
  import OrderDialog from '@/app/core/components/Order/OrderDialog'
  import SecretText from '@/app/core/components/SecretText'
  import DataCard from '@/app/core/components/DataCard'

  import {byRegistryHostName as getDomainSearchFilterByRegistryHostName}
    from '@/app/pages/Domain/components/DomainFilter'

  import {HOST_STATES}
    from '@/app/core/components/RegistryObject/StatesDialog'

  const statesFlat = [
    ...HOST_STATES.client,
    ...HOST_STATES.extended
  ]

  export default {
    name: 'HostData',

    components: {
      NameValueBlock,
      OrderDialog,
      DataCard
    },

    props: {
      host: {
        type: Object,
        default: undefined
      },
      history: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      domainSearchLink () {
        return {
          name: 'domain.search',
          query: {
            filter: JSON.stringify (
              getDomainSearchFilterByRegistryHostName (
                this.host.registryId, this.host.domainName))
          }
        }
      },

      sections () {
        return [
          {l: 'basic', i: this.basicData, show: true},
          {l: 'dates', i: this.dates, show: true},
          {l: 'providerchains', i: this.providerchains, show: true},
          {l: 'ips', i: this.ips, show: this.ips.length},
          {l: 'states', i: this.states, show: this.states.length},
          {l: 'addOn', i: this.addOn, show: this.addOn.length > 0}
        ]
      },

      registryTypeName () {
        return this.$route.params.registry
      },

      handle () {
        return this.$route.params.handle || this.host.handle
      },

      versionId () {
        return this.$route.params.id
      },

      basicData () {
        return [
          {
            name: this.$t ('state.label'),
            value: this.$t (this.host.deleted
              ? 'state.deleted'
              : 'state.active')
          },
          {
            name: this.$t ('label.handle'),
            value: this.host.handle
          },
          {
            name: this.$t ('label.registryType'),
            value: true,
            slotValue: this.$createElement (RegistryTypeLink, {
              props: {
                value: this.host.registryId,
                short: true
              }
            })
          },
          {
            name: this.$t ('label.associatedDomains'),
            value: this.$t ('label.clickToSearch'),
            link: this.domainSearchLink
          },
          ...[
            '',
            'creator',
            'updater'
          ].map (type => {
            const memberId = type
              ? this.host[toCamelCase (type, 'memberId')]
              : this.host.clientId

            return {
              name: this.$t (`label.${toCamelCase (type, 'client')}`),
              value: !!memberId || EmptyMark,
              ...(
                memberId
                  ? {
                    slotValue: this.$createElement (ClientLink, {
                      props: {
                        id: memberId
                      }
                    })
                  }
                  : {}
              )
            }
          }),

          ...(
            this.host.authInfo
              ? [{
                name: this.$t ('label.authInfo'),
                value: true,
                slotValue: this.$createElement (SecretText, {
                  props: {
                    value: this.host.authInfo
                  }
                })
              }]
              : []
          ),
          {
            name: this.$t ('label.maintainer'),
            value: this.host.maintainer ? this.host.maintainer : EmptyMark
          }
        ]
      },

      dates () {
        return [
          'creationDate',
          'updateDate',
          'transferDate'
        ].map (this.propMapper (this.host, v =>
          v ? this.formatDate (v) : EmptyMark))
      },

      ips () {
        return this.host.ips
          ? this.host.ips.map ((ip) => ({
            name: ip,
            value: ' '
          }))
          : []
      },

      providerchains () {
        return [{
          name: this.host.providerChainType,
          value: this.host.providerChainSpec
        }]
      },

      states () {
        let states = []

        if (this.host?.states?.length) {
          states = [...this.host.states].sort ((a, b) =>
            statesFlat.indexOf (a) - statesFlat.indexOf (b)).map (it =>
            ({name: it, value: ' '}))
        }

        return states
      },

      addOn () {
        const addOnData = []
        const fields = this.host.addOnFields

        for (const field in fields) {
          if (fields.hasOwnProperty (field)) {
            addOnData.push ({
              name: 'addon' + field,
              value: fields[field]
            })
          }
        }

        return addOnData
      }
    },

    methods: {
      /**
       * mapper for some object property name to object, which can be used as
       * argument for name-value component
       *
       * @param o         object, which property should be mapped
       * @param valCb     value callback function (possibility to transform
       *                  actual value, e.g. format the value)
       *
       * @return {function(*): {name: *, value: *}}
       */
      propMapper (o, valCb = v =>
        v || (typeof v === 'boolean' ? v : EmptyMark)) {
        return p => (
          {
            name: this.$t (`label.${p}`),
            value: valCb (o[p])
          }
        )
      }
    }
  }
</script>
