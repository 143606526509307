<template>
  <v-btn
    v-t="'general.button.back'"
    text
    @click="back"/>
</template>

<script>
  export default {
    methods: {
      back () {
        this.$router.back ()
      }
    }
  }
</script>
