<template>
  <v-tooltip
    v-model="show"
    bottom
    :disabled="!show"
    @input="onInput">
    <template #activator="{ on }">
      <slot
        name="activator"
        v-on="on"/>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
  export default {
    name: 'Notification',

    props: {
      value: {
        type: Boolean,
        required: true
      },
      text: {
        type: String,
        required: true
      }
    },

    data () {
      return {
        show: false
      }
    },

    watch: {
      value (newValue) {
        this.show = newValue
        this.$emit ('input', this.value)
      }
    },

    created () {
      this.show = this.value
    },

    methods: {
      onInput () {
        this.show = false
        this.$emit ('input', this.show)
      }
    }
  }
</script>
