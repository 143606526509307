<template>
  <simple-card
    :card-class="['dataCard', cardClass]"
    :title="title"
    title-class="py-1"
    content-class="px-6 pb-6">
    <slot/>
  </simple-card>
</template>

<script>
  import SimpleCard from '@/app/core/components/SimpleCard'

  export default {
    name: 'DataCard',

    components: {SimpleCard},

    props: {
      cardClass: {
        type: [Array, Object, String],
        default: undefined
      },

      title: {
        type: String,
        required: true
      }
    }
  }
</script>
