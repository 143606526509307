<messages>["./Order"]</messages>

<template>
  <base-dialog
    v-model="dialog"
    mw1
    scrollable
    close-on-esc>
    <template #activator="{ on }">
      <div
        v-on="on"
        @click.prevent="loadOrder ()">
        <slot name="activator"/>
      </div>
    </template>
    <v-card>
      <v-card-title>
        <div>
          <template v-if="objectId && orderId">
            <div class="text-h5">
              {{ $t ('view.title.' + type, { object: objectId }) }}
            </div>
            <div class="cgwng-subheading">
              {{ $t ('view.subtitle', { id: orderId }) }}
            </div>
          </template>
          <div
            v-else
            v-t="'view.title.order'"
            class="text-h5"/>
        </div>
      </v-card-title>
      <v-card-text>
        <v-slide-y-transition mode="out-in">
          <v-container
            v-if="isLoading"
            key="loading">
            <v-row>
              <v-progress-linear
                indeterminate
                color="primary"/>
            </v-row>
          </v-container>
          <template v-else-if="!hasErrors">
            <v-container key="order" class="pa-0">
              <!-- meta data -->
              <v-subheader
                v-t="'view.label.meta.header'"
                class="pa-0 mb-2"/>
              <v-row class="mx-0">
                <v-col cols="12" lg="6" class="py-0">
                  <name-value-block :items="meta"/>
                </v-col>
              </v-row>

              <!-- source description -->
              <template v-if="sourceDescription">
                <v-subheader
                  v-t="'view.label.source'"
                  class="pa-0 mt-4"/>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-card
                      class="pa-2"
                      flat
                      :color="sourceColor">
                      {{ sourceDescription }}
                    </v-card>
                  </v-col>
                </v-row>
              </template>

              <!-- order -->
              <v-subheader
                v-t="'view.label.order'"
                class="pa-0 mt-4"/>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-card
                    class="px-2"
                    flat
                    :color="sourceColor">
                    <payload
                      v-model="sourceText"
                      readonly/>
                  </v-card>
                </v-col>
              </v-row>

              <!-- response -->
              <v-subheader
                v-t="'view.label.response'"
                class="pa-0 mt-4"/>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-card
                    class="pa-2"
                    flat
                    color="grey lighten-2">
                    <payload
                      v-model="result.payloadResult"
                      readonly/>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-else>
            <v-container key="notFound">
              <v-col cols="12">
                <v-alert type="error">
                  {{ $t (`error.${errorType}`, { id: orderId }) }}
                </v-alert>
              </v-col>
            </v-container>
          </template>
        </v-slide-y-transition>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          v-t="'general.button.close'"
          text
          @click.native="dialog = false"/>
      </v-card-actions>
    </v-card>
  </base-dialog>
</template>

<script>
  import {mapActions} from 'vuex'
  import {EmptyMark} from '@/app/utils/string'

  import BaseDialog from '@/app/core/components/BaseDialog'
  import NameValueBlock from '@/app/core/components/NameValueBlock'
  import Payload from '@/app/core/components/CodeEditor/Payload'

  export default {
    name: 'OrderDialog',

    components: {
      BaseDialog,
      NameValueBlock,
      Payload
    },

    props: {
      orderId: {
        type: Number,
        required: true
      },
      objectId: {
        type: [String, Number],
        required: true
      },
      type: {
        type: String,
        required: true,
        validator (v) {
          return [
            'domain',
            'contact',
            'host',
            'zone',
            'emailVerification',
            'bookEntry'
          ].includes (v)
        }
      }
    },

    data () {
      return {
        isLoading: true,
        dialog: false,
        result: null,
        hasErrors: false,
        errorType: ''
      }
    },

    computed: {
      hasActivatorSlot () {
        return !!this.$slots.activator
      },

      meta () {
        return [
          'creation',
          'completion',
          'type',
          'resultCode',
          'status',
          'txnClient',
          'txnServer',
          'txnRegistry',
          'effectiveId',
          'submitterId',
          'sourceType'
        ].map (i => (
          {
            name: this.$t (`view.label.meta.${i}`),
            value: this.convertedResult
              ? this.convertedResult[i]
                ? this.convertedResult[i]
                : EmptyMark
              : EmptyMark
          }
        ))
      },

      convertedResult () {
        if (!this.result) {
          return null
        }
        return {
          ...this.result,
          creation: this.result.creation
            ? this.formatDate (this.result.creation)
            : null,
          completion: this.result.completion
            ? this.formatDate (this.result.completion)
            : null,
          status: this.result.status
            ? this.$t (`view.label.status.${this.result.status}`)
            : null
        }
      },

      sourceColor () {
        if (this.result.payloadRequest) {
          switch (this.result.sourceType) {
            case 'CP':
              return 'lime lighten-5'
            case 'JSON':
              return 'cyan lighten-5'
            default:
              return 'grey lighten-2'
          }
        } else {
          return 'grey lighten-2'
        }
      },

      sourceText () {
        if (this.result.payloadRequest) {
          return this.result.payloadRequest
        } else if (this.result.payloadSource) {
          return this.result.payloadSource
        } else {
          return ''
        }
      },

      sourceDescription () {
        return this.result.payloadSource
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      loadOrder () {
        if (!this.result && !this.hasErrors) {
          if (this.orderId) {
            this.isLoading = true

            this.fetchData ({
              op: 'order/load',

              params: {
                id: this.orderId
              },

              cb: data => {
                // remove sensitive data from payload
                // TODO, FIXME: delete the block after fix in BE
                ['payloadRequest', 'payloadResult', 'payloadSource'].forEach (
                  field => {
                    if (data[field]) {
                      data[field] = data[field].replace (
                        /(transaction\.[ao]tp:\s*)\S+/i, '$1*****')
                    }
                  })
                this.result = data
              },
              cbError: () => {
                this.hasErrors = true
                this.errorType = 'notFound'
              },
              cbFinal: () => {
                this.isLoading = false
              }
            })
          } else {
            this.isLoading = false
            this.hasErrors = true
            this.errorType = 'notExist'
          }
        }
      }
    }
  }
</script>
