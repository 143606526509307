import { render, staticRenderFns } from "./OrderType.vue?vue&type=template&id=29020c1e&scoped=true&"
import script from "./OrderType.vue?vue&type=script&lang=js&"
export * from "./OrderType.vue?vue&type=script&lang=js&"
import style0 from "./OrderType.vue?vue&type=style&index=0&id=29020c1e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29020c1e",
  null
  
)

/* custom blocks */
import block0 from "./OrderType.vue?vue&type=custom&index=0&blockType=messages"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
installComponents(component, {VChip})
