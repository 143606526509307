export const CURRENT = 0
export const HISTORIC = 1

const ID_URL_SUFFIX = '.view.id'
const VIEW_URL_SUFFIX = '.view'
const VERSION_URL_SUFFIX = '.view.version'

export default {
  props: {
    vid: {
      type: [String, Number],
      default: undefined
    },
    id: {
      type: [String, Number],
      default: undefined
    }
  },

  data () {
    return {
      historicObjectData: undefined,
      activeTab: 0,
      expanded: [0],
      objectId: undefined,
      objectVersionId: undefined
    }
  },

  methods: {
    /**
     * Set the active tab to the given tab index.
     *
     * @param {Number} tab      the tab index to set
     */
    setActiveTab (tab) {
      this.activeTab = tab
    },

    /**
     * Whether the history panel with the given index is expanded.
     *
     * @param {Number} index     the history panel index
     * @returns {Boolean}        true if the panel is expanded
     */
    isHistoryPanelExpanded (index) {
      return this.expanded.includes (index)
    },

    /**
     * Collapse all history panels.
     */
    collapseAllHistoryPanels () {
      this.expanded = []
    },

    /**
     * Expand (only) the history panel with the given index.
     *
     * @param {Number} index      the index of the history panel to expand
     */
    expandHistoryPanel (index) {
      this.expanded = [index]
    },

    /**
     * Navigate to the element with the id version-{currentVersionId}
     */

    navigateToCurrentVersion () {
      if (this.objectVersionId) {
        try {
          this.$nextTick (() => {
            this.$vuetify.goTo ('#version-' + this.objectVersionId)
          })
        } catch (ex) {
          console.log (ex)
        }
      }
    },

    /**
     * Expand the history panel with the data of the loaded object version
     * (if exists, else collapse all panels).
     */
    expandHistoryPanelWithLoadedVersion () {
      if (this.vid) {
        for (const i in this.historicObjectData) {
          const loadedVersionId = Number.parseInt (this.vid)
          const panelVersionId = this.historicObjectData[i].versionId

          if (panelVersionId === loadedVersionId) {
            this.expandHistoryPanel (+i)
            break
          }
        }
      } else {
        this.collapseAllHistoryPanels ()
      }
    },

    /**
     * Set the ID of the currently displayed object.
     *
     * @param {Number} id     the object ID
     */
    setObjectId (id) {
      this.objectId = id
    },

    /**
     * Set the ID of the currently displayed object version.
     *
     * @param {Number} id     the object version ID
     */
    setObjectVersionId (id) {
      this.objectVersionId = id
    },

    /**
     * Rewrite the URL based on the active tab (current or historic). Moreover,
     * when the historic tab is activated and no entry is selected, the first
     * entry will be expanded.
     */
    rewriteUrl () {
      if (this.activeTab === HISTORIC) {
        if (this.expanded.length === 0) this.expanded = [0]
        this.useVersionIdUrl (this.objectVersionId)
      } else if (this.activeTab === CURRENT && this.getObjectType (this.$route.name) === 'domain') {
        this.$router.replace ({
          name: this.getObjectType (this.$route.name) + VIEW_URL_SUFFIX,
          params: {
            ...this.$route.params,
            name: this.name
          }
        })
      } else if (this.activeTab === CURRENT) {
        this.$router.replace ({
          name: this.getObjectType (this.$route.name) + ID_URL_SUFFIX,
          params: {
            ...this.$route.params,
            vid: undefined,
            id: this.objectId
          }
        })
      }
    },

    /**
     * Use a URL containing the version ID of the currently displayed object
     * version.
     *
     * @param {Number} vid      the ID of the displayed object version
     */
    useVersionIdUrl (vid) {
      if (vid) {
        this.$router.replace ({
          name: this.getObjectType (this.$route.name) + VERSION_URL_SUFFIX,
          params: {
            ...this.$route.params,
            id: undefined,
            vid
          }
        })
      }
    },

    /**
     * Read the object type from the current route.
     * @param {Object} route      the route
     */
    getObjectType (route) {
      return route.substring (0, route.indexOf ('.'))
    }
  },

  watch: {
    activeTab () {
      this.rewriteUrl ()
    },

    objectVersionId (versionId) {
      this.useVersionIdUrl (versionId)
    }
  }
}
